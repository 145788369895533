<template>
  <div class="toolbars">
    <GoBack  backPos="forum"/>
    <el-tabs
      v-model="activeName"
      class="editor-tabs my-tab"
      :before-leave="beforeLeave"
      >
      <el-tab-pane label="普通编辑器" name="basic">
      </el-tab-pane>
      <!-- <el-tab-pane label="markdown编辑器" name="markdown">
      </el-tab-pane> -->
      <el-tab-pane label="markdown编辑器" name="markdown">
      </el-tab-pane>
    </el-tabs>
  </div>
  <div class="publish-forum">
    <div class="editor">
      <el-card>
        <el-form :model="formInline" class="el-my-form">
          <el-form-item required :class="['form-title', `form-title-${activeName}`, isTitleFocus ? 'focused': '']">
            <el-input
              v-model="formInline.title"
              placeholder="请输入标题"
              maxlength="40"
              @input="showDialog"
              @keydown.enter="editorFocus"
              @focus="optDisabled"
              @blur="showOptions"
            ></el-input>
          </el-form-item>
          <template  v-if="activeName === 'basic'">
            <UEEditor :content="formInline.content" ref="editorRef"></UEEditor>
          </template>
          <!-- <template  v-else-if="activeName === 'markdown'">
            <MarkdownEditor v-model="formInline.content" ref="editorRef"></MarkdownEditor>
          </template> -->
          <template  v-else>
            <Vditor  :content="formInline.content" :type="isEdit ? 'edit' : 'new'" ref="editorRef"></Vditor>
          </template>
          <div class="operate-footer">
            <div class="oper-left">
              <el-form-item label="论坛" required>
                <el-select v-model="formInline.cateId" placeholder="选择论坛">
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in cateList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="分类" v-if="formInline.cateId && tagList?.length" class="tag-list">
                <el-select v-model="formInline.tagId" placeholder="选择分类">
                  <el-option
                    :label="item.name"
                    :value="item.id"
                    v-for="item in tagList"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="check-share" v-if="!isEdit">
                <el-checkbox
                  class="share-to-blog"
                  v-model="formInline.type"
                  label="将帖子分享至微博"
                  name="type"
                ></el-checkbox>
              </el-form-item>
            </div>
            <div>
              <el-form-item>
                <el-button
                  class="pub-forum"
                  :disabled="btnDisabled"
                  @click="onSubmit"
                  @mouseenter="hoverSubmitBtn"
                  >{{isEdit ? '保存编辑' : '发布帖子'}}</el-button
                >
              </el-form-item>
            </div>
          </div>
          <template v-if="formInline.type">
            <div class="editor-share">
              <CommonEditor
                buttonText=""
                editorType="forum"
                placeholder="说说你分享的理由~"
                :limit="limit"
                :initInputContent="formInline.share_content"
                ref="commonEditorRef"
              >
              </CommonEditor>
            </div>
          </template>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script setup>
import { reactive, toRefs, computed, onMounted, watch } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import { forumPublish, editForumApi } from '@/apis/forum.js'
import mySocket from '@/mixin/socket.js'
import { myToast, confirm } from '@/utils/dialogMsg.js'
import { getAtUserID } from '@/utils/tools'
import CommonEditor from '@/components/commonEditor/CommonEditor.vue'
import UEEditor from './editor/UEEditor.vue'
// import MarkdownEditor from './editor/MarkdownEditor.vue'
import Vditor from './editor/Vditor.vue'

const router = useRouter()
const route = useRoute()
const store = useStore()
const { sendToSocket } = mySocket()

// 绑定跳转过来的分区
const state = reactive({
  formInline: {
    user: '',
    cateId: Number(route.query.cateId || route.params.cate_id) || '',
    tagId: Number(route.query.tagId || route.params.tag_id) || '',
    type: false,
    title: '',
    content: '',
    share_content: '',
    at_user: [],
    contentNumber: Number(0),
    created_at: ''
  },
  activeName: '',
  isTitleFocus: true,
  cateList: computed(() => store.state.forum.pubData),
  tagList: computed(() => store.state.forum.pubData.find(item => item.id === state.formInline.cateId)?.tags),
  limit: '1200',
  btnDisabled: computed(
    () =>
      !(
        state.formInline.title &&
        state.formInline.content &&
        state.formInline.cateId > 0 &&
        (state.formInline.contentNumber
          ? state.formInline.contentNumber
          : 0) <= state.limit
      )
  ),
  commonEditorRef: null,
  isEdit: false,
  post_id: undefined,
  editorRef: null
})

const { formInline, activeName, isTitleFocus, cateList, tagList, limit, commonEditorRef, isEdit, editorRef, btnDisabled } = toRefs(state)

watch(() => state.formInline.cateId, (newValue, oldValue) => {
  state.formInline.tagId = ''
})

const hoverSubmitBtn = () => {
  state.formInline.content = state.editorRef.getContent()
}

// 标题长度校验
const showDialog = () => {
  if (state.formInline.title.length >= 40) {
    myToast({ type: 'warning', message: '标题最多输入40个字符' })
  }
}

// focus编辑器
const editorFocus = () => {
  state.isTitleFocus = false
  state.editorRef.editorFocus()
}

// 编辑器操作栏禁用
const optDisabled = () => {
  state.isTitleFocus = true
  state.editorRef.optDisabled()
}

// 编辑器操作栏恢复
const showOptions = () => {
  state.isTitleFocus = false
  state.editorRef.showOptions()
}

const beforeLeave = async (activeName, oldActive) => {
  // 首次赋值，不弹窗
  if (oldActive === '0') {
    state.activeName = activeName
    return
  }
  //  没内容进行切换
  state.formInline.content = state.editorRef.getContent()
  if (state.formInline.content.length === 0) {
    return
  }
  return await new Promise((resolve, reject) => {
    confirm({
      message: '切换编辑器，将清理帖子内容，确认切换吗？',
      success: function () {
        state.formInline.content = ''
        // state.editorRef.clearContent()
        return resolve(true)
      },
      cancel: function () {
        // eslint-disable-next-line prefer-promise-reject-errors
        return reject(false)
      }
    })
  })
}

// 发布动态
const onSubmit = () => {
  const submitObject = {
    cate_id: state.formInline.cateId,
    tag_id: (state.tagList.length && state.formInline.tagId) ? state.formInline.tagId : 0,
    content: state.formInline.content,
    from: 1,
    title: state.formInline.title,
    share: state.formInline.type ? 2 : 1,
    post_id: state.post_id,
    editor_type: state.activeName === 'basic' ? 1 : 2
  }

  // 分享到动态，添加参数
  if (state.formInline.type) {
    submitObject.at_user = getAtUserID(
      state.commonEditorRef.inputContent?.html
    )
    submitObject.image_url = state.commonEditorRef.getImageData().map(item => item.url).join('^')

    submitObject.share_content =
      state.formInline.title +
      '</br></br>' +
      (state.commonEditorRef.inputContent?.html || '')
  }
  // 提交
  state.isEdit ? editForum(submitObject) : addForum(submitObject)
}

// 发布帖子
const addForum = async (submitObject) => {
  const res = await forumPublish(submitObject)
  if (res.code === 0) {
    // 分享动态
    if (submitObject.share === 2) {
      // 微博小红点提示
      sendToSocket(undefined, { type: 1, feedID: res.data.feed_id })
      // @ta人
      sendToSocket(submitObject.at_user, { type: 4 })
    }

    afterSuccess(res.data.post_id)
  } else {
    myToast({ type: 'error', message: res.msg })
  }
}

const editForum = async (submitObject) => {
  const res = await editForumApi(submitObject)
  if (res.code === 0) {
    afterSuccess(res.data.post_id)
  } else {
    myToast({ type: 'error', message: res.msg })
  }
}

const afterSuccess = (id) => {
  myToast({ type: 'success', message: `${state.isEdit ? '修改' : '发布'}成功` })
  state.formInline = {}
  // 清空编辑器内容
  state.editorRef.clearContent()
  // 跳转详情页面
  router.push({
    name: 'articalDetail',
    params: { id }
  })
}

// 进入页面从vuex里面取数据
onMounted(() => {
  state.formInline = store.state.forum.postInfo
  state.formInline.cateId = Number(route.query.cateId) || ''
  state.formInline.tagId = Number(route.query.tagId) || ''
  // 默认选择上次编辑器
  if (state.formInline?.editor_type === 1) {
    state.activeName = 'basic'
  } else {
    state.activeName = 'markdown'
  }
  // 光标定位标题
  // setTimeout(() => {
  //   document.querySelector('#title').focus()
  // }, 100)
  // 判断是否是编辑帖子
  if (route.params.title) {
    const newValue = route.params
    state.formInline.cateId = Number(newValue.cate_id) || ''
    state.formInline.tagId = Number(newValue.tag_id) || ''
    state.formInline.title = newValue.title
    state.formInline.content = newValue.content
    state.formInline.created_at = newValue.created_at
    state.post_id = newValue.articalId
    state.editor_type = Number(newValue.editor_type) || 1
    state.activeName = state.editor_type === 1 ? 'basic' : 'markdown'
    state.isEdit = true
  }
})

// 离开页面保存信息
onBeforeRouteLeave(() => {
  state.formInline.content = state.editorRef.getContent()
  state.formInline.editor_type = state.activeName === 'basic' ? 1 : 2
  store.commit('forum/setPostInfo', state.formInline)
})
</script>

<style lang="less" scoped>
.toolbars{
  position: relative;
  .back{
    // width: 932px;
    margin:0 0 8px;
  }
  .editor-tabs{
    position: absolute;
    top: 0;
    left: 120px;
  }
}

.el-my-form {
  margin-top: 0 !important;
  position: relative;
  :deep(>textarea){
    display:none
  }
}
.editor {
  // width: 932px;
  margin: 0 auto;
  .el-card {
    :deep(.el-card__body) {
      padding: 0 !important;
    }
  }
}

.el-form {
  :deep(.el-form-item__content) {
    line-height: unset;
  }
  .el-checkbox {
    line-height: 40px;
  }
  .el-form-item__label,
  .el-checkbox__label {
    font-size: 16px;
    font-weight: 700;
  }
  .el-checkbox__label {
    width: 150px;
  }
}

.form-title{
  position: absolute;
  width: 80%;
  width: 932px;
  background: #fff;
  top: 0;
  &.form-title-basic {
    z-index: 100;
    top: 50px;
    padding-top: 24px;
    :deep(.el-input__inner) {
      padding-left: 48px;
    }
  }
  &.form-title-markdown{
    top: 37px;
    z-index: 1;
    margin: 0 10px;
    :deep(.el-input__inner) {
      padding-left: 0;
    }
  }

  :deep(.el-input__inner) {
    width: 100%;
    border: 0;
    font-weight: bold;
    font-size: 20px;
    color: @default-text-color;
  }
  :deep(.el-input__count) {
    display: none;
  }
  :deep(.el-input__inner::-webkit-input-placeholder) {
    color: @default-text-color;
  }
}

.operate-footer {
  display: flex;
  padding: 18px 24px;
  border-top: 1px solid #f1f1f1;
  justify-content: space-between;
  position: relative;
  z-index: 99;
  background: white;
  .oper-left {
    display: flex;
  }
  .el-form-item {
    margin: 0;
  }
  .tag-list{
      margin-left: 31px;
    }
  .check-share {
    margin-left: 31px;
  }
  .el-select {
    :deep(.el-input) {
      width: 133px;
      height: 30px;
    }
    :deep(.el-input__inner) {
      border-radius: 32px !important;
      border-color: #f1f1f1 !important;
      color: @default-text-color !important;
      background: #f6f9fa !important;
    }
    :deep(.el-input__inner::-webkit-input-placeholder) {
      color: @default-text-color;
    }
    :deep(.el-input__suffix-inner i) {
      color: @default-text-color !important;
    }
  }

  :deep(.el-form-item__label),
  :deep(.el-form-item__content),
  :deep(.el-select),
  :deep(.el-checkbox__label),
  :deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
    color: @default-text-color !important;
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
    background: @active-text-color;
    border-color: #ccc;
  }
  :deep(.el-checkbox__inner) {
    border-color: #ccc;
  }
}
.editor-share {
  padding: 0 23px 0 23px;
  position: relative;
  z-index: 101;
  :deep(.emoji-picker) {
    top: -180px;
    z-index: 100;
  }
}
.pub-forum {
  width: 133px;
  border-radius: 20px;
  background: #7972f0 !important;
  color: #fff;
  border: none;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #e4e3fc;
}
.el-select-dropdown__item.selected {
  color: @default-text-color;
  font-weight: normal;
}
.pub-forum.is-disabled,
.pub-forum.is-disabled:hover {
  color: #fff;
}

</style>
