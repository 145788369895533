<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-11-15 13:44:02
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-12-05 18:00:17
-->
<template>
  <div>
    <div ref="vditorRef" :class="['vditor', props.type === 'view' ? 'no-border' : '']"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineExpose, defineProps, computed, defineEmits } from 'vue'
import Vditor from 'vditor'
import 'vditor/dist/index.css'
import { uploadFile } from '@/apis/userCenter.js'

const props = defineProps({
  content: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'view' // 新增-new 编辑-edit 查看详情-view
  }
})

const emits = defineEmits(['setHeight'])
const onlyView = computed(() => props.type === 'view')
const vditorRef = ref(null)
const vditorInstance = ref(null)

const toolbar = [
  { name: 'emoji', tip: '表情' },
  { name: 'headings', tip: '标题' },
  { name: 'bold', tip: '加粗' },
  { name: 'italic', tip: '斜体' },
  { name: 'strike', tip: '删除线' },
  { name: 'link', tip: '链接' },
  '|',
  { name: 'list', tip: '无序列表' },
  { name: 'ordered-list', tip: '有序列表' },
  { name: 'check', tip: '任务列表' },
  { name: 'outdent', tip: '减少缩进' },
  { name: 'indent', tip: '增加缩进' },
  '|',
  { name: 'line', tip: '分隔线' },
  { name: 'quote', tip: '引用' },
  { name: 'code', tip: '代码块' },
  { name: 'inline-code', tip: '行内代码' },
  { name: 'insert-after', tip: '后插入' },
  { name: 'insert-before', tip: '前插入' },
  '|',
  { name: 'upload', tip: '上传文件' },
  { name: 'table', tip: '表格' },
  { name: 'undo', tip: '撤销' },
  { name: 'redo', tip: '重做' }
  // '|',
  // { name: 'edit-mode', tip: '切换编辑模式' },
  // { name: 'more', tip: '更多' }
]
const editorFocus = () => {
  vditorInstance.value.enable()
  vditorInstance.value.focus()
}

const clearContent = () => {
  vditorInstance.value.setValue('')
}

const setContent = (value) => {
  vditorInstance.value.setValue(value)
}

const getContent = () => {
  const content = vditorInstance.value.getValue()
  const notEmpty = content.trim() !== ''
  return notEmpty ? content : ''
}

const optDisabled = () => {
  vditorInstance.value.disabled()
}

// 显示按钮
const showOptions = () => {
  vditorInstance.value.enable()
}

onMounted(() => {
  if (!vditorRef.value) {
    return
  }
  vditorInstance.value = new Vditor(vditorRef.value, {
    height: props.type === 'new' ? 800 : 'auto',
    placeholder: '请输入帖子内容',
    lang: 'zh_CN', // 设置语言为中文
    mode: onlyView.value ? 'wysiwyg' : 'sv', //  ir (即时渲染)、wysiwyg (所见即所得)、或 sv (分屏) 模
    toolbar: toolbar, //
    toolbarConfig: {
      pin: !onlyView.value, // 是否固定工具栏
      hide: onlyView.value // 是否隐藏工具栏
    },
    // counter: {
    //   enable: true,
    //   max: 1200,
    //   after (length) {
    //     // 字数统计
    //     console.log(length)
    //   }
    // },
    cache: {
      enable: false
    },
    after () {
      // 初始化编辑器内容
      vditorInstance.value.setValue(props.content || '')
      if (props.type === 'view') { // 查看详情、编辑
        vditorInstance.value.disabled()
        const height = vditorInstance.value.vditor.element.offsetHeight
        emits('setHeight', height)
      }
    },
    tab: '\t',
    upload: {
      accept: '*',
      filename (name) { // 文件名安全处理
        return name.replace(/[^(a-zA-Z0-9\u4e00-\u9fa5.)]/g, '').replace(/[?\\/:|<>*[]()$%{}@~]/g, '').replace('/\\s/g', '')
      },
      handler (fileList) {
        fileList.forEach(file => {
          // 将图片上传服务器
          uploadFile(file, 2, () => {}, 2).then((res) => {
            if (res.code === 0) {
              let markdownImageSyntax = ''
              if (file.type.includes('image')) { // 是否图片
                markdownImageSyntax = '!'
              }
              // 在当前光标位置插入文件
              vditorInstance.value.insertValue(`${markdownImageSyntax}[${file.name}](${res.data.url})`)
            }
          }).catch((err) => {
            console.log(err)
          })
        })
      }
    }
  })
})

onBeforeUnmount(() => {
  // if (vditorInstance.value) {
  //   vditorInstance.value.destroy()
  // }
})

defineExpose({
  clearContent,
  setContent,
  getContent,
  editorFocus,
  optDisabled,
  showOptions
})

</script>

<style lang="less" scoped>
:deep(.vditor.no-border){
  border: none;
}
:deep(.vditor-content){
  padding-top: 50px;
}

:deep(.vditor-preview__action){
  display: none;
}
:deep(.vditor-sv.vditor-reset), :deep(.vditor-preview){
  background-color: #fff !important;
}
:deep(.vditor-toolbar--hide){
  width: 0px;
  border-bottom: 0;
  padding-left: 0 !important;
}
:deep(.vditor-ir pre.vditor-reset){
  padding-top: 0;
}
:deep(.vditor-ir pre.vditor-reset[contenteditable="false"]),
:deep(.vditor-wysiwyg pre.vditor-reset[contenteditable="false"]) {
  opacity: 1;
  padding: 0 !important;
}

:deep(.vditor-tooltipped__n:after),
:deep(.vditor-tooltipped__ne:after),
:deep(.vditor-tooltipped__nw:after) {
  right: 50%;
  bottom: -30px;
  margin-bottom: 5px;
}

:deep(.vditor-tooltipped__n:before),
:deep(.vditor-tooltipped__ne:before),
:deep(.vditor-tooltipped__nw:before) {
  top: -5px;
  right: 50%;
  bottom: auto;
  margin-right: -5px;
  border-top-color: #3b3e43;
}

:deep(.vditor-tooltipped__ne:after) {
  right: auto;
  margin-left: -15px;
}

:deep(.vditor-tooltipped__nw:after) {
  margin-right: -15px;
}

:deep(.vditor-tooltipped__n:after) {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

</style>
