
import {
  ElButton,
  ElDropdownMenu,
  ElDropdown,
  ElDropdownItem,
  ElInput,
  ElInputNumber,
  ElForm,
  ElFormItem,
  ElCarousel,
  ElCarouselItem,
  ElBadge,
  ElSubmenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElMenu,
  ElCard,
  ElAvatar,
  ElImageViewer,
  ElDivider,
  ElBacktop,
  ElTabs,
  ElTabPane,
  ElTable,
  ElTableColumn,
  ElInfiniteScroll,
  ElIcon,
  ElCol,
  ElRow,
  ElUpload,
  ElImage,
  ElProgress,
  ElSelect,
  ElOption,
  ElCheckbox,
  ElCheckboxGroup,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElLoading,
  ElSkeleton,
  ElSkeletonItem,
  ElMessage,
  ElMessageBox,
  ElDialog,
  ElPagination,
  ElEmpty,
  ElAffix,
  ElCalendar,
  ElScrollbar,
  ElTag,
  ElTree,
  ElTooltip,
  ElLink,
  ElDatePicker,
  ElAside,
  ElContainer,
  ElHeader,
  ElMain,
  ElRadioGroup,
  ElRadio,
  ElSwitch
} from 'element-plus'
// import lang from 'element-plus/lib/locale/lang/zh-cn'
// import { use } from 'element-plus/lib/locale'

export default (app) => {
  // use(lang)
  app.use(ElButton)
  app.use(ElDropdownMenu)
  app.use(ElDropdown)
  app.use(ElDropdownItem)
  app.use(ElInput)
  app.use(ElInputNumber)
  app.use(ElForm)
  app.use(ElFormItem)
  app.use(ElCarousel)
  app.use(ElCarouselItem)
  app.use(ElBadge)
  app.use(ElSubmenu)
  app.use(ElMenuItem)
  app.use(ElMenuItemGroup)
  app.use(ElMenu)
  app.use(ElCard)
  app.use(ElAvatar)
  app.use(ElImageViewer)
  app.use(ElDivider)
  app.use(ElBacktop)
  app.use(ElTabs)
  app.use(ElTabPane)
  app.use(ElTable)
  app.use(ElTableColumn)
  app.use(ElInfiniteScroll)
  app.use(ElIcon)
  app.use(ElCol)
  app.use(ElRow)
  app.use(ElUpload)
  app.use(ElImage)
  app.use(ElProgress)
  app.use(ElSelect)
  app.use(ElOption)
  app.use(ElCheckbox)
  app.use(ElCheckboxGroup)
  app.use(ElBreadcrumbItem)
  app.use(ElBreadcrumb)
  app.use(ElSkeleton)
  app.use(ElLoading)
  app.use(ElSkeletonItem)
  app.use(ElMessage)
  app.use(ElMessageBox)
  app.use(ElDialog)
  app.use(ElPagination)
  app.use(ElEmpty)
  app.use(ElAffix)
  app.use(ElCalendar)
  app.use(ElScrollbar)
  app.use(ElTag)
  app.use(ElTree)
  app.use(ElTooltip)
  app.use(ElLink)
  app.use(ElDatePicker)
  app.use(ElAside)
  app.use(ElContainer)
  app.use(ElHeader)
  app.use(ElMain)
  app.use(ElRadioGroup)
  app.use(ElRadio)
  app.use(ElSwitch)
}
