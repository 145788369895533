<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-11-26 15:54:03
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-12-12 15:28:03
-->
<template>
    <div class="option-form">
      <el-form label-position="top" :model="virtualForm" ref="formRef"
        :class="isOverHeight ? 'over-height' : ''">
        <template v-for="({name, type, required},index) in formItem" :key="index">
          <el-form-item
            :label="name"
            props="content"
            :rules="[{ required: Boolean(required), validator: (rule, value, callback, {name, type, required}, index) => checkContent(rule, value, callback, type, index), trigger: ['blur', 'change'] }]">
            <template v-if="type === '1'">
              <el-input v-model="form[index].value" />
            </template>
            <template v-else-if="type === '2'">
              <el-input v-model="form[index].value"  type="textarea" rows="5" />
            </template>
            <template v-else-if="type === '3'">
              <ImageInfo
                :draggable="true"
                :ref="el => setRefIndex(el, type)"
                :id="Number(new Date().getTime())"
                :limit="9"
                :showLength="Number(0)"
              ></ImageInfo>
            </template>
            <template v-else-if="type === '4'">
                <UploadFile
                  v-show="!form[index].value"
                  @uploadFileInf="(type, file) => uploadVideo(type, file, index)"
                  @uploadPercent="(percent) => uploadPercent(percent, index)"
                  :limit="1"
                  type="mp4"
                  :disabled="props.disabled">
                  <template #mySlot>
                    <svg width="117" height="117" viewBox="0 0 117 117" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".5" y=".5" width="116" height="116" rx="5.5" fill="#F6F9FA" stroke="#F1F1F1"/><rect x="57" y="34" width="4" height="48" rx="2" fill="#C4C4C4"/><rect x="83" y="56" width="4" height="48" rx="2" transform="rotate(90 83 56)" fill="#C4C4C4"/></svg>
                  </template>
                </UploadFile>
                <VideoInfo
                  :ref="el => setRefIndex(el, type)"
                  @removeVideo="uploadVideo('', {url: ''}, index)">
                </VideoInfo>
            </template>
          </el-form-item>
        </template>
      </el-form>
      <template v-if="props.isButtonShow">
        <el-button
          type="primary"
          size="small"
          @click="submit"
          :disabled="isBtnDisabled">创建选项</el-button>
      </template>
      <template v-if="defaultHeight > 0 && (formRefHeight > defaultHeight)">
        <p class="hideOrMore" @click="handlerClick">
          <template v-if="isOverHeight">
            <span>展开</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 8l5 5 5-5" stroke="#7972F0" stroke-width="2"/></svg>
          </template>
          <template v-else>
            <span>收起</span>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 12l5-5 5 5" stroke="#7972F0" stroke-width="2"/></svg>
          </template>
        </p>
      </template>
    </div>
</template>

<script setup>
import { ref, defineExpose, defineProps, computed, watchEffect, defineEmits, nextTick } from 'vue'
import ImageInfo from '@/components/blog/ImageInfo.vue'
import VideoInfo from '@/components/blog/VideoInfo.vue'
import { addVoteOption } from '@/apis/vote.js'
import { dialogMsg } from '@/utils/dialogMsg.js'
// import { debounce } from '@/utils/tools.js'
const dialogVisible = ref(false)
const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  disabled: {
    type: Boolean,
    default: false
  },
  isButtonShow: {
    type: Boolean,
    default: true
  },
  voteId: {
    type: Number,
    default: 0
  },
  defaultHeight: {
    type: Number,
    default: 0
  }
})
const defaultHeight = computed(() => props.defaultHeight)
const maxHeight = ref(170)
const formItem = computed({
  set: (value) => {
    return value
  },
  get: () => {
    return props.data
  }
})
const form = ref([])
const formRef = ref(null)
const virtualForm = ref({ content: '' })
const imageInfoRef = ref([])
const videoInfoRef = ref([])
const formRefHeight = ref(0)
const isOverHeight = computed(() => defaultHeight.value > 0 && formRefHeight.value > maxHeight.value)
const emit = defineEmits('updateOptions')
const isBtnDisabled = computed({
  set: (value) => {
    return value
  },
  get: () => {
    return props.disabled
  }
})
computed(() => props.disabled)
const checkContent = (list) => {
  return list.some(({ name, value, type, required }) => {
    if (required && !value) {
      dialogMsg('error', `必填项${name}不能为空`)
      return true
    }
  })
}

const submit = () => {
  const isFileUploading = isUploading()
  if (isFileUploading) {
    dialogMsg('error', '文件正在上传中，请勿提交')
    return false
  }
  const elements = getFormData()
  if (!checkContent(elements)) { // 检查必填项
    addVoteOption({ vote_id: props.voteId, elements }).then((res) => {
      if (res.code === 0) {
        emit('updateOptions', props.voteId)
        dialogMsg('success', '创建投票选项成功')
        // 清空图片和视频数据
        imageInfoRef.value.forEach(ref => { ref.imageList = [] })
        videoInfoRef.value.forEach(ref => { ref.reset() })
        form.value = []
      } else {
        dialogMsg('error', res.msg)
      }
    })
  }
}

watchEffect(() => {
  form.value = []
  if (Array.isArray(props.data)) {
    props.data.forEach(({ name, type, required, value }, index) => {
      // required有可能是true/false,或者是1/0
      form.value.push({ name, type, required: Boolean(required), value: value || '' })
    })
  }
  nextTick(() => {
    formRefHeight.value = formRef.value.$el.offsetHeight
  })
})

const uploadVideo = (type, file, index) => {
  // 更新视频链接
  form.value[index].value = file.url
  const videoIndex = form.value.filter(item => item.type === '4').findIndex((_, i) => form.value.filter(item => item.type === '4')[i] === form.value[index])
  videoInfoRef.value[videoIndex].videoUrl = file.url
}

const uploadPercent = (percent, index) => {
  // 更新上传进度
  const videoIndex = form.value.filter(item => item.type === '4').findIndex((_, i) => form.value.filter(item => item.type === '4')[i] === form.value[index])
  videoInfoRef.value[videoIndex].percentage = percent
}

const setRefIndex = (el, type) => {
  const refList = type === '3' ? imageInfoRef.value : videoInfoRef.value
  refList.push(el)
}

const getFormData = () => {
  const data = JSON.parse(JSON.stringify(form.value))
  data.forEach((item, index) => {
    // 处理图片数据
    if (item.type === '3') {
      const refIndex = data.filter(item => item.type === '3').findIndex((_, i) => data.filter(item => item.type === '3')[i] === data[index])
      const refList = imageInfoRef.value
      item.value = refList[refIndex].imageList.map(item => item.url).join('^') // 取值
    }
    // 处理required字段
    item.required = item.required ? 1 : 0
  })
  return data
}

const handlerClick = () => {
  maxHeight.value = isOverHeight.value ? 9999 : defaultHeight.value
}

// 是否有图片/视频在上传中
const isUploading = () => {
  const isImageUploading = imageInfoRef.value.some(ref => ref?.imageList.some(image => !image.url))
  const isVideoUploading = videoInfoRef.value.some(ref => ref?.percentage > 0 && ref?.percentage < 100)
  // return { isImageUploading, isVideoUploading }
  return isImageUploading && isVideoUploading
}

defineExpose({
  dialogVisible,
  getFormData
})
</script>

<style lang="less" scoped>
.el-form{
  height: auto;
  &.over-height{
    height: 170px;
    overflow: hidden;
  }
}

.el-form-item{
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0 !important;
  }
}

:deep(.el-form-item__label) {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 6px;
}

:deep(.el-form-item__content){
  line-height: unset;
}
:deep(.file-list) {
  margin-top: 0;
}

:deep(.el-input__inner:focus),
:deep(.el-input__inner:hover),
:deep(.el-textarea__inner:focus),
:deep(.el-textarea__inner:hover){
  border-color: #DCDFE6;
}

.hideOrMore{
  display: flex;
  align-items: center;
  color: #7972f0;
  justify-content: center;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
  margin-top: 20px;
}

.el-button{
  width: 306px;
  height: 44px;
  transform: translateX(60px);
  margin-top: 40px;
}
</style>
