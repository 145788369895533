<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-05-13 13:54:07
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-11-15 15:39:43
-->

<template>
    <vue-ueditor-wrap
        v-if="isActive"
        v-model="text"
        :config="editorConfig"
        :editor-id="`editorUe${random}`"
        @ready="readyEditor"
      ></vue-ueditor-wrap>
</template>

<script setup>
import { reactive, toRefs, computed, defineProps, onMounted, onBeforeUnmount, defineExpose } from 'vue'
import { getCookies } from '@/utils/auth'

const props = defineProps({
  content: {
    type: String,
    default: ''
  }
})

const state = reactive({
  ueditorInstance: null,
  random: 0,
  isActive: true,
  text: computed(() => props.content),
  editorConfig: {
    UEDITOR_HOME_URL: '/UEditor/', // 访问 UEditor 静态资源的根路径，可参考常见问题1
    serverUrl:
        process.env.VUE_APP_URL + 'ueditor/index' + '?token=' + getCookies('token'), // 上传文件url
    initialFrameHeight: '730',
    initialFrameWidth: 'auto',
    focus: false,
    autoFloatEnabled: true,
    autoHeightEnabled: false,
    insertorderedlist: {
      decimal: '',
      'lower-alpha': '', // 'a,b,c...'
      'lower-roman': '', // 'i,ii,iii...'
      'upper-alpha': '', // 'A,B,C'
      'upper-roman': '' // 'I,II,III...'
    },
    insertunorderedlist: {
      circle: '', // '○ 小圆圈'
      disc: '', // '● 小圆点'
      square: '' // '■ 小方块'
    },
    toolbars: [
      [
        'undo', // 撤销
        'redo', // 重做
        'bold', // 加粗
        'italic', // 斜体
        'forecolor', // 字体颜色
        'backcolor', //  背景颜色
        'link', // 超链接
        'underline', // 下划线
        'strikethrough', // 删除线
        'horizontal', // 分隔线
        'insertimage', // 多图上传
        'emotion', // 表情
        'insertvideo', // 视频
        'justifyleft', // 居左对齐
        'justifyright', // 居右对齐
        'justifycenter', // 居中对齐
        'justifyjustify', // 两端对齐
        'insertorderedlist', // 有序列表
        'insertunorderedlist', // 无序列表
        'lineheight', // 行间距
        'fontfamily', // 字体sss
        'fontsize', // 字号
        'paragraph', // 段落格式
        'inserttable',
        'deletetable',
        'insertparagraphbeforetable',
        'attachment'
      ]
    ]
  }

})

const { random, isActive, text, editorConfig } = toRefs(state)

const readyEditor = (editorInstance) => {
  state.ueditorInstance = editorInstance
}

const editorFocus = () => {
  state.ueditorInstance.focus()
}

const clearContent = () => {
  state.ueditorInstance.setContent('')
}

const getContent = () => {
  return state.ueditorInstance.getContent()
}

const optDisabled = () => {
  state.ueditorInstance.disable()
}

// 显示按钮
const showOptions = () => {
  state.ueditorInstance.enable()
}

onMounted(() => {
  state.ueditorInstance = null
  state.random = new Date().getTime()
})

onBeforeUnmount(() => {
  state.isActive = false
  setTimeout(() => {
    state.ueditorInstance && state.ueditorInstance.destroy && state.ueditorInstance.destroy()
  }, 0)
})

defineExpose({
  clearContent,
  getContent,
  editorFocus,
  optDisabled,
  showOptions
})
</script>

<style lang="less" scoped>

:deep(.edui-editor) {
  z-index: 80 !important;
}
:deep(.edui-default .edui-editor-breadcrumb) {
  display: none;
}
</style>
