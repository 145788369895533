<template >
  <div :class="['file-list', size]" v-if="imageList.length >= props.showLength">
    <Vuedraggable
      class="vue-draggable"
      :class="{ disabled: disabled }"
      v-model="srcList"
      @end="onDragEnd"
      :item-key="createUniqueString()"
    >
     <template #item="{ element, index }">
      <div :key="index" class="image-wrap">
        <el-image
          :src="element.includes('image/format,jpg') ? element + '/resize,m_fill,h_320,w_320' : element + '?x-oss-process=image/resize,m_fill,h_320,w_320'"
          :preview-src-list="[element]"
          :initial-index="0"
          :class="{'half-loading':element.startsWith('/img')}"
          fit='cover'
        >
        </el-image>
        <svg @click="deleteFile(index)" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle opacity=".5" cx="10" cy="10" r="10" fill="#666"/><rect x="12.829" y="5.757" width="2" height="10" rx="1" transform="rotate(45 12.829 5.757)" fill="#fff"/><rect x="14.243" y="12.828" width="2" height="10" rx="1" transform="rotate(135 14.243 12.828)" fill="#fff"/></svg>
      </div>
     </template>
     <template #footer>
        <template v-if="draggable">
          <UploadFile  @uploadFileInf="uploadFileInf" :limit="limit" >
            <template #mySlot v-if="imageList.length < 9 ">
              <svg width="117" height="117" viewBox="0 0 117 117" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x=".5" y=".5" width="116" height="116" rx="5.5" fill="#F6F9FA" stroke="#F1F1F1"/><rect x="57" y="34" width="4" height="48" rx="2" fill="#C4C4C4"/><rect x="83" y="56" width="4" height="48" rx="2" transform="rotate(90 83 56)" fill="#C4C4C4"/></svg>
            </template>
          </UploadFile>
        </template>
     </template>
    </Vuedraggable>
  </div>
</template>
<script setup>
import { computed, reactive, toRefs, defineProps, defineExpose, onMounted, onBeforeUnmount } from 'vue'
import eventBus from '@/utils/eventBus.js'
import Vuedraggable from 'vuedraggable'
import { createUniqueString } from '@/utils/tools'
import { DeletePending } from '@/utils/cancelRequest'

const props = defineProps({
  limit: {
    type: Number,
    default: 1
  },
  size: {
    type: String,
    default: ''
  },
  draggable: {
    type: Boolean,
    default: false
  },
  id: {
    type: Number,
    default: 0
  },
  showLength: {
    type: Number,
    default: 1
  }
})

const state = reactive({
  disabled: false,
  imageList: [],
  limit: computed(() => props.limit),
  draggable: computed(() => props.draggable),
  srcList: computed(() => {
    return state.imageList.map(item => item.url || require('@/assets/img/common/uploading.gif'))
  })
})

const { disabled, imageList, limit, draggable, srcList } = toRefs(state)

const addFile = (file, id) => {
  if (props.id === id) {
    if (file.url) { // 显示图片
      state.imageList = state.imageList.map(item => {
        return item.uid === file.uid ? file : item
      })
    } else { // 显示loading
      state.draggable ? state.imageList.push(file) : state.imageList = [file]
    }
  }
}

const uploadFileInf = (type, file) => {
  addFile(file, props.id)
}

const deleteFile = (index) => {
  state.imageList.splice(index, 1)
  DeletePending()
}

function onDragEnd (e) {
  const oldIndex = e.oldIndex // 旧索引
  const newIndex = e.newIndex // 新索引
  // 从原来的位置移除，再添加到指定位置
  state.imageList.splice(newIndex, 0, state.imageList.splice(oldIndex, 1)[0])
}

onMounted(() => {
  eventBus.$on('uploadImage', ({ file, id }) => {
    if (file) {
      addFile(file, id)
    }
  })
})

onBeforeUnmount(() => {
  eventBus.$off('uploadImage')
})

defineExpose({
  imageList,
  uploadFileInf
})

</script>

<style lang="less" scoped>

.file-list{
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  .vue-draggable{
    display: flex;
    flex-wrap: wrap;
  }
  svg{
    cursor: pointer;
  }
  &.small .el-upload svg{
    width: 74px;
    height: 74px;
  }
  .el-image,:deep(.el-upload--picture-card),:deep(.el-upload-list--picture-card .el-upload-list__item){
    width: 115px;
    height: 117px;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 6px;
    background: #f6f9fa;
    border: 1px solid #f1f1f1;
  }
  &.small {
    .el-image,
    :deep(.el-upload--picture-card),
    :deep(.el-upload-list--picture-card .el-upload-list__item)
    {
      width: 74px;
      height: 74px;
      border: none;
      margin-right: 10px;
    }
  }
  :deep(.el-image.half-loading ){
    .el-image__inner{
      transform: scale(0.5);
    }
  }
  .image-wrap{
    position: relative;
    svg{
      position: absolute;
      top: 6px;
      right: 16px;
      cursor: pointer;
      &:hover circle{
        fill: @active-text-color;
        opacity: 1;
      }
    }
  }
   &.small .image-wrap svg{
    top: -9px;
    right: 0px;
   }
  :deep(.el-upload-dragger){
    width: unset;
    height: unset;
    border: none;
  }

}
</style>
