<!--
 * @Descripttion: 选择日志接收人
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2022-01-26 09:24:03
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-11-28 14:32:23
-->
<template>
  <div class="transfer-wrapper">
    <el-scrollbar class="transfer-left border-6">
      <div class="search-box">
        <el-select
          v-model="filterText"
          filterable
          remote
          reserve-keyword
          placeholder="请输入用户名"
          :loading="loading"
          :remote-method="getUserByName"
          v-inputMax="20"
        >
          <el-option
            v-for="item in options"
            :key="item.ding_user_id"
            :label="item.name"
            :value="item.ding_user_id"
            @click="clickUser(item, 'search')"
          >
            <!-- <el-checkbox v-model="item.selected"  :disabled="item.disabled"></el-checkbox> -->
            <div class="user-info">
              <UserAvatar :isPreview="true" :user="item" size="24" fontSize="10" medalSize="36"></UserAvatar>
              <span class="del-blue">{{ item.name }}</span>
            </div>
          </el-option>
        </el-select>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 9A6 6 0 1 1 3 9a6 6 0 0 1 12 0zm-1.015 6.258a8 8 0 1 1 1.273-1.273l3.796 3.796a.9.9 0 0 1-1.273 1.273l-3.796-3.796z" fill="#333"/></svg>
      </div>
      <el-breadcrumb separator=">">
        <el-breadcrumb-item el-breadcrumb-item v-for="item in leftBread" :key="item.dept_id" @click="getTeam(item)">{{ item.name }}</el-breadcrumb-item>
      </el-breadcrumb>
      <ul class="user-list">
        <li v-if="leftData.length > 0">
          <el-checkbox v-model="checkAll" @change="selectAll($event, leftData)"></el-checkbox>
          {{`全选 (${allCount})`}}
        </li>
        <li v-for="item in leftData" :key="item.name">
          <el-checkbox v-model="item.selected" :disabled="item.disabled" @change="selectItem(item)"></el-checkbox>
          <template v-if="item.dept_id">
            <span @click="getTeam(item)" class="dep-item">
              <img src="@/assets/img/company.svg" />
              {{ `${item.name} (${item.dept_count})` }}
            </span>
          </template>
          <template v-else>
            <div class="user-info" @click="clickUser(item)">
              <UserAvatar :isPreview="true" :user="item" size="24" fontSize="10" medalSize="36"></UserAvatar>
              <span>{{ item.name }}</span>
            </div>
          </template>
        </li>
      </ul>
    </el-scrollbar>
    <el-scrollbar class="transfer-right border-6">
      <ul class="user-list">
        <li class="rigth-item" v-for="item in rightData" :key="item.name" :data-id="item.id">
          <span>
            <template  v-if="item.dept_id">
              <img src="@/assets/img/company.svg" />
              {{ `${item.name} (${item.dept_count})`}}
            </template>
            <template v-else>
              <UserAvatar :isPreview="true" :user="item" size="24" fontSize="10" medalSize="36"></UserAvatar>
              {{item.name}}
            </template>
          </span>
          <span @click="removeItem(item)" :class="{ disabled: item.disabled}">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="9.899" y="8.536" width="2" height="12" rx="1" transform="rotate(135 9.9 8.536)" fill="#333"/><rect x="1.414" y="9.95" width="2" height="12" rx="1" transform="rotate(-135 1.414 9.95)" fill="#333"/></svg>
          </span>
        </li>
      </ul>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, toRefs, computed, onMounted, watch, nextTick } from 'vue'
import { getTeamList, getTeamUserList } from '@/apis/journal.js'
import { dialogMsg } from '@/utils/dialogMsg.js'

export default {
  props: {
    userIds: { // 已选择的用户列表
      type: Array,
      default: () => []
    },
    disableUserIds: { // 不可删除的用户列表
      type: Array,
      default: () => []
    },
    currentUser: {
      type: Object,
      default: () => {}
    },
    noMaxNum: { // 限制是否有最大选中人数
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    onMounted(() => {
      getAllTeam()
    })

    watch(() => props.userIds, (newValue) => {
      if (newValue && state.userList) {
        initData()
      }
    })

    const state = reactive({
      leftBread: [], // 面包屑组织架构
      teamData: {
        children: [],
        user: [],
        dept_id: ''
      },
      leftData: [],
      rightData: [],
      rightIds: computed(() => state.rightData.map(item => item.dept_id || item.ding_user_id)),
      allCount: computed(() => { return getAllCount() }),
      checkAll: computed({
        set: (val) => state.leftData.forEach(item => {
          if (item.disabled) {
            item.selected = true
          } else {
            item.selected = val
          }
        }),
        get: () => (state.leftData.every(item => item.selected))
      }),
      filterText: '',
      userList: [], // 总用户列表
      options: [], // 搜索结果列表
      loading: false,
      defaultUserIds: computed(() => props.userIds),
      max: computed(() => !props.noMaxNum ? Number(process.env.VUE_APP_MAX) : 999999) // 可选择的最大人数
    })

    // 获取组织架构
    const getAllTeam = () => {
      state.leftBread = []
      const data = { type: 2 }
      getTeamList(data).then(res => {
        if (res.code === 0) {
          // 渲染面包屑导航
          initLeftBread(res.data)
          // 左侧渲染数据
          getLeftData(state.teamData, 'init')
          // 搜索用户列表
          getUserList(state.teamData.dept_id)
        }
      })
    }
    // 左边的数据
    const getLeftData = (data, type = 'click') => {
      state.leftData = []
      data.children && state.leftData.push(...data.children)
      data.user && state.leftData.push(...data.user)
      // 勾选左侧
      if (type === 'init') {
        state.leftData.forEach(item => {
          item.disabled = props.disableUserIds.includes(item.ding_user_id) // 不能被删除
          state.defaultUserIds.includes(item.ding_user_id) && (item.selected = true)
          if (props.noMaxNum) {
            item.selected = true
          }
        })
      } else { // 点击切换
        state.leftData.forEach(item => {
          item.disabled = props.disableUserIds.includes(item.ding_user_id) // 不能被删除
          if (state.rightIds.includes(item.ding_user_id || item.dept_id)) {
            item.selected = true
          } else {
            item.selected = false
          }
        })
      }
    }

    // 计算总数
    const getAllCount = () => {
      let count = 0
      state.leftData.forEach(item => {
        if (item.dept_id) {
          count += item.dept_count
        } else {
          count += 1
        }
      })
      return count
    }
    // 点击整行，选择用户
    const clickUser = (user, type) => {
      if (user.dept_id || user.disabled) return
      if (type === 'search' && state.rightIds.includes(user.ding_user_id)) return
      user.selected = !user.selected
      selectItem(user, type)
    }
    // 勾选单个
    const selectItem = (obj, type = 'select') => {
      if (obj.selected) { // 去重添加
        // 判断选择是否超过限制
        const rigthCount = state.rightData.reduce((acc, cur) => acc + (cur.dept_count || 1), 0)
        if (rigthCount + (obj.dept_count || 1) > state.max) {
          obj.selected = false
          dialogMsg('error', `选择超过${state.max}人限制`)
          return
        }
        !state.rightIds.includes(obj.dept_id || obj.ding_user_id) && state.rightData.unshift(obj)
      } else { // 删除
        if (obj.dept_id) {
          state.rightData = state.rightData.filter(item => (item.dept_id !== obj.dept_id))
        } else {
          state.rightData = state.rightData.filter(item => (item.ding_user_id !== obj.ding_user_id))
        }
      }
      // 搜索添加
      if (type === 'search') {
        getUserByName()
        const user = state.leftData.filter(item => item.ding_user_id === obj.ding_user_id)
        user.length && (user[0].selected = true)
      }
    }
    // 全选
    const selectAll = (value, data) => {
      if (value) { // 全选
        // 判断选择是否超过限制
        const count = data.reduce((acc, cur) => acc + (cur.dept_count || 1), 0)
        if (count > state.max) {
          state.checkAll = false
          dialogMsg('error', `选择超过${state.max}人限制`)
          return
        }
        // 去重添加
        data.forEach(item => {
          if (item.selected && !state.rightIds.includes(item.dept_id || item.ding_user_id)) {
            state.rightData.push(item)
          }
        })
      } else { // 取消全选
        // 删除右侧数据
        data.forEach(item => {
          if (item.disabled) return
          item.selected = false
          if (state.rightIds.includes(item.dept_id || item.ding_user_id)) {
            const index = state.rightIds.findIndex(id => (id === item.dept_id || id === item.ding_user_id))
            state.rightData.splice(index, 1)
          }
        })
      }
    }
    // 删除
    const removeItem = (item) => {
      if (item.disabled) return false
      state.rightData = state.rightData.filter(i => i !== item)
      nextTick(() => {
        // 左边去除勾选
        state.leftData.forEach(data => {
          if (item.dept_id && data.dept_id === item.dept_id) {
            data.selected = false
          } else if (item.ding_user_id && data.ding_user_id === item.ding_user_id) {
            data.selected = false
          }
        })
        // 搜索选择列表去除勾选
        const data = state.options.filter(user => user.ding_user_id === item.ding_user_id)
        data && data.length > 0 && (data[0].selected = false)
      })
    }

    // 获取团队信息
    const getTeam = (item) => {
      console.log('getTeamgetTeamgetTeam')
      if (item.selected) return false
      getLeftData(item)
      if (item.level === 1) {
        state.leftBread.splice(1, state.leftBread.length - 1)
      } else if (item.level > state.leftBread.length) {
        state.leftBread.push(item)
      } else if (item.level !== state.leftBread.length) {
        state.leftBread.splice(item.level, state.leftBread.length - item.level)
      }
    }

    // 获取所有用户
    const getUserList = (id) => {
      getTeamUserList({ dept_id: id }).then(res => {
        if (res.code === 0) {
          state.userList.push(...res.data)
          initData()
        }
      })
    }

    // 初始化已选择用户
    const initData = () => {
      state.rightData = []
      state.userList.forEach(item => {
        state.defaultUserIds.includes(item.ding_user_id) && !state.rightIds.includes(item.ding_user_id) && state.rightData.push(item)
        item.disabled = props.disableUserIds.includes(item.ding_user_id) // 不能被删除
      })
      state.leftData.forEach(item => {
        if (item.ding_user_id) { //  默认选中用户
          item.selected = state.defaultUserIds.includes(item.ding_user_id)
          item.disabled = props.disableUserIds.includes(item.ding_user_id) // 不能被删除
        }
      })
    }
    //  根据名字查询
    const getUserByName = (value) => {
      state.filterText = value
      if (value) {
        state.loading = true
        setTimeout(() => {
          state.loading = false
          state.options = state.userList.filter((item) => {
            if (state.rightIds.includes(item.ding_user_id)) {
              item.selected = true
            } else {
              item.selected = false
            }
            if (item.ding_user_id === props.currentUser.user_id) {
              item.disabled = true
            }
            return item.name.includes(state.filterText)
          })
        }, 200)
      } else {
        state.options = []
      }
    }
    const initLeftBread = (data) => {
      state.leftBread = []
      state.teamData = data
      state.teamData.name = '全部'
      state.leftBread.push(state.teamData)
    }

    // 重置数据
    const resetData = () => {
      initLeftBread(state.teamData)
      getLeftData(state.leftBread[0])
      initData()
    }

    return {
      ...toRefs(state),
      getAllTeam,
      clickUser,
      selectItem,
      removeItem,
      selectAll,
      getLeftData,
      getTeam,
      initData,
      getUserByName,
      resetData
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.el-breadcrumb__inner){
  cursor: pointer;
}
:deep(.el-breadcrumb__inner:hover){
  color: @active-text-color;
}
.transfer-wrapper {
  display: flex;
  justify-content: space-between;
  & > div {
    width: 360px;
    height: 416px;
    padding: 10px 16px;
    background: #fff;
  }
}
.transfer-left{
  margin-right: 8px;
}
.el-breadcrumb{
  margin: 20px 0 10px;
  font-size: 12px;
  line-height: 16px;
}
:deep(.el-breadcrumb__separator){
  margin: 0 2px;
  color: @second-text-color;
}

li {
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  .el-checkbox,.dep-item{
    padding: 10px 0;
  }
  .el-checkbox{
    margin-right: 15px;
  }
  // 覆盖多选框样式
  :deep(.el-checkbox__inner){
    background: @colorWhite;
    border-radius: 50%;
    border: 1px solid #E0E0E0;
    width: 18px;
    height: 18px;
    &:hover{
      border: 1px solid #E0E0E0;
    }
    &::after{
      border-width: 2px;
      left: 6px;
      top: 2px
    }
  }
  :deep(.el-checkbox__input.is-checked .el-checkbox__inner){
    background-color: @active-text-color;
    border-color: @active-text-color;
  }

  :deep(.el-checkbox__input.is-focus .el-checkbox__inner){
    border-color: @active-text-color;
  }
  .el-select-dropdown  {
    :deep(.el-select-dropdown__item.hover, .el-select-dropdown__item:hover) {
      background-color: transparent;
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    width: 100%;
    .del-blue{
      color: #606266;
    }
  }

  &.rigth-item {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    >span{
      display: flex;
      align-items: center;
      img{
        margin-right: 13px;
      }
      &.disabled{
        cursor: not-allowed;
      }
    }
    .svg {
      cursor: pointer;
    }
  }
}
.dep-item{
  display: flex;
  align-items: center;
  width: 100%;
  &:hover {
    cursor: pointer;
    background: #F1F4F4;
    border-radius: 6px;
  }
  img{
    margin-right: 4px;
  }
}

.search-box {
  position: relative;
  .el-select{
    width: 100%;
    :deep(.el-select__tags) {
      max-width: calc(100% - 44px) !important;
    }
  }
  :deep(.el-input__inner){
    border-radius: 32px;
    border: 1px solid #F1F1F1;
    background: #F6F9FA;
  }
  :deep(.el-select .el-input.is-focus .el-input__inner){
    border: 1px solid #F1F1F1;
    cursor: unset;
  }
  svg{
    position: absolute;
    right: 18px;
    top: 10px;
    cursor: pointer;
  }
}
</style>
