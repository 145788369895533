<!--
 * @Descripttion: 投票创建组件
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-03-08 18:12:49
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-12-13 11:05:54
-->

<template>
  <div :class="['vote-dialog-box',
    voteOptionFormRef.length > 2 ? 'vote-dialog-setting' : '']">
    <div class="vote-content">
      <p class="title">发起投票</p>
      <span class="vote-close" @click="close"></span>
      <el-scrollbar max-height="60vh" :min-size="10" ref="voteRef">
        <el-form :model="voteForm" label-width="88px" label-position="left"  :rules="voteRules" ref="voteFormRef">
          <template v-for="{label, key, list} in voteSetting" :key="key">
            <el-form-item  :label="`${label}:`" :prop="key">
              <template v-if="key === 'title'">
                <el-input v-model="voteForm[key]" placeholder="请输入" clearable :maxlength="50"/>
              </template>
              <template v-else-if="key === 'is_option_fixed' || key === 'is_anonymous' || key === 'max_vote_nums' ">
                <el-radio-group v-model="voteForm[key]" @change="changeRadio($event, key)">
                  <el-radio v-for="{value, name} in list" :key="value" :label="value">{{name}}</el-radio>
                </el-radio-group>
                <div class="limit-num" v-if="key === 'max_vote_nums' && voteForm['max_vote_nums'] === 2" >
                  <el-input-number v-model="limitNum" size="small" :min="2" :max="maxOptionNum"/>
                  <p>封闭式投票时，数量不大于投票选项数</p>
                </div>
              </template>
              <template v-else-if="key === 'deadline_time'">
                <el-date-picker
                  :default-value="defaultTime"
                  :disabled-date="disabledDate"
                  placeholder="请输入"
                  format="YYYY-MM-DD HH:mm"
                  value-format="YYYY-MM-DD HH:mm"
                  v-model="voteForm[key]"
                  type="datetime"
                  popper-class="select-time"
                  clearable
                  @change="getDateTime"
                  />
              </template>
              <template v-else-if="key === 'scope'">
                <el-checkbox-group v-model="voteForm[key]" class="scope-input"></el-checkbox-group>
                <p :class="['scope-desc', {'current-text': receiveUserName}]" @click="getReceiveUser">
                  <!-- {{ receiveUserName || '选中用户可见' }} -->
                  {{ allUserList.length === receiveUserIds.length ?  '所有用户可见' : receiveUserName }}
                </p>
              </template>
            </el-form-item>
          </template>
          <!-- 开放式投票 -->
          <template v-if="voteForm.is_option_fixed === 0">
            <div class="el-form-item-option">
              <el-form-item
                label="投票设置"
                prop="options_list"
                >
                <p class="option-list-setting option-list-setting-title">
                  <span class="first-span">选项名称</span><span>选项类型</span><span>必填</span>
                </p>
              </el-form-item>
            </div>
            <VoteOptionSetting ref="voteOptionSettingRef" :data="voteForm.options_config"></VoteOptionSetting>
          </template>
          <!-- 封闭式投票 -->
          <template v-else-if="voteForm.is_option_fixed === 1">
            <div class="el-form-item-option">
              <el-form-item
                label="投票内容"
                :required="true"
                prop="options"
                >
                <div class="option-list-setting" @click="openOptionSettingDialog(false)" >
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="17.347" y="1.181" width="2" height="13.219" rx="1" transform="rotate(45 17.347 1.18)" fill="#7972F0"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11 4H4v12h12v-6h2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h7v2z" fill="#7972F0"/></svg>
                  <span class="primary-text cursor-pointer">投票选项设置</span>
                </div>
              </el-form-item>
            </div>
            <template v-for="(options, optionsIndex) in voteForm.options" :key="optionsIndex">
              <el-form-item
                :label="`选项${optionsIndex+1}`"
                props="options"
                :rules= "[{ required: true,  validator: (rule, value, callback) => checkNull(rule, value, callback, '投票内容'), trigger: ['blur', 'change'] }]">
                <div class="option-list-box">
                  <!-- 默认内容 -->
                  <template v-if="voteForm.options_list?.length === 0">
                    <p class="option-default-name">投票内容</p>
                    <div class="option-default-list">
                      <el-input placeholder="请输入" v-model="voteForm.options[optionsIndex].title" />
                    </div>
                  </template>
                  <!-- 自定义内容 -->
                  <template v-else>
                    <VoteOptionForm
                        :ref="el => voteOptionFormRef[optionsIndex] = el"
                        :data="!isInit ? voteForm.options_list : voteForm.options_list[optionsIndex]"
                        :isButtonShow="false"
                        :defaultHeight="Number(170)">
                      </VoteOptionForm>
                  </template>
                  <!-- 删除按钮 -->
                  <template v-if="optionsIndex > 1">
                    <DeleteIcon
                      :isTextShow="false"
                      deleteText="这项投票内容"
                      @handleClick="delOption(optionsIndex)"
                      ></DeleteIcon>
                  </template>
                </div>
              </el-form-item>
            </template>
            <p class="add-icon" @click="addOption">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="3" y="9" width="14" height="2" rx="1" fill="#333"/><rect x="11" y="3.002" width="14" height="2" rx="1" transform="rotate(90.068 11 3.002)" fill="#333"/></svg>
              <span>添加选项</span>
            </p>
          </template>
        </el-form>
      </el-scrollbar>
      <div class="vote-btn">
        <el-button type="primary" size="small" @click="close">取消</el-button>
        <el-button type="primary" size="small" @click="toCreateVote" :disabled="submitDisabled">确定</el-button>
        <template v-if="state.voteForm.is_option_fixed === 0">
            <ViewIcon :count="-1" @handleClick="openOptionSettingDialog(true)"></ViewIcon>
            <span class="cursor-pointer">预览</span>
        </template>
      </div>
    </div>
    <!-- 选择用户弹窗 -->
    <el-dialog custom-class="vote-select-user" v-model="receiveVisible" center :show-close="false" @close="hideReceiveUser" append-to-body>
      <div class="select-user">
        <p class="user-dialog-title">选择可见范围</p>
        <SelectUser ref="receiveUserRef"
          :userIds="receiveUserIds"
          :disableUserIds="disableUserIds"
          :currentUser="currentUser"
          :noMaxNum="true"
          >
        </SelectUser>
        <div class="button-box">
          <el-button @click="hideReceiveUser" type="primary" size="small">取消</el-button>
          <el-button type="primary" size="small" @click="addReceiveUser">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 开发式投票预览弹窗、封闭式投票投票选项设置弹窗 -->
    <el-dialog
      :custom-class="!isPrev ? 'vote-option-dialog vote-option-setting-dialog' : 'vote-option-dialog'"
      v-model="optionSettingVisible"
      :show-close="false"
      :width="isPrev ? '500px' : '564px'">
        <p class="vote-option-dialog-title">{{ isPrev ? '创建投票选项' : '投票选项设置' }} </p>
        <span class="close-icon" @click="optionSettingVisible = false"></span>

        <template v-if="isPrev">
          <VoteOptionForm :data="voteForm.options_list" :disabled="true"></VoteOptionForm>
        </template>
        <template v-else>
          <p class="vote-option-title-desc">
            <span class="first-span">选项名称</span><span>选项类型</span><span>必填</span>
          </p>
          <VoteOptionSetting ref="optionSettingRef"></VoteOptionSetting>
          <div class="vote-setting-btn">
            <el-button @click="optionSettingVisible = false" type="primary" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="setOptionSetting">确定</el-button>
          </div>
        </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, toRefs, computed, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { formatAllDate } from '@/utils/voteUtil.js'
import SelectUser from '@/views/layout/work/writeJournal/component/SelectUser.vue'
import { getTeamUserList } from '@/apis/journal.js'
import { reBackTime } from '@/utils/tools'
import ViewIcon from '@/components/icon/ViewIcon.vue'
import DeleteIcon from '@/components/icon/DeleteIcon.vue'
import VoteOptionSetting from './VoteOptionSetting'
import VoteOptionForm from './VoteOptionForm'
import { myToast } from '@/utils/dialogMsg.js'
const store = useStore()
const checkNull = (rule, value, callback, name) => {
  if (!value || !value.trim()) {
    return callback(new Error(`${name}不能为空`))
  } else {
    callback()
  }
}

// 校验投票选项/内容
const checkOptionsList = (rule, value, callback) => {
  let text = ''
  let flag = false
  if (state.voteForm.is_option_fixed === 0) { // 开放式投票
    flag = checkVoteSettingRequired(state.voteOptionSettingRef.optionsList, false)
    if (flag) { // 是否全部非必填
      text = '投票设置最少设置一个必填项'
    } else {
      flag = !checkVoteSetting(state.voteOptionSettingRef.optionsList, false)
      text = '投票设置的选项名称或选项类型不能为空'
    }
  } else if (state.voteForm.is_option_fixed === 1) { // 封闭式投票
    if (state.voteForm.options_list.length === 0) { // 默认内容
      const index = value.findIndex(item => !item.title)
      flag = index > -1
      text = `投票内容选项${index + 1}不能为空`
    } else { // 自定义投票选项
      let emptyIndex = 0
      const data = state.voteOptionFormRef.map(item => item.getFormData())
      flag = data.some((group, index) =>
        group.some(item => {
          if (item.required === 1 && !item.value) {
            emptyIndex = index
            return true
          }
        })
      )
      text = `投票内容选项${emptyIndex + 1}必填项不能为空`
    }
  }
  if (flag) {
    return callback(new Error(`${text}`))
  } else {
    callback()
  }
}
const voteSetting = [
  {
    key: 'title',
    label: '投票标题'
  },
  {
    key: 'is_option_fixed',
    label: '投票类型',
    list: [
      {
        value: 0,
        name: '开放式投票'
      },
      {
        value: 1,
        name: '封闭式投票'
      }
    ]
  },
  {
    key: 'is_anonymous',
    label: '投票方式',
    list: [
      {
        value: 0,
        name: '实名'
      },
      {
        value: 1,
        name: '匿名'
      }
    ]
  },
  {
    key: 'max_vote_nums',
    label: '投票数量',
    list: [
      {
        value: 1,
        name: '单选'
      },
      {
        value: 2,
        name: '多选'
      },
      {
        value: 999999,
        name: '不限'
      }
    ]
  },
  {
    key: 'deadline_time',
    label: '截止时间'
  },
  {
    key: 'scope',
    label: '可见范围'
  }
]
const allUserList = computed(() => store.state.userList.filter(item => item.user_type === 1))
const state = reactive({
  voteFormRef: null,
  voteForm: {
    id: 0,
    is_option_fixed: 0,
    is_anonymous: 0,
    title: '',
    max_vote_nums: 1,
    deadline_time: '',
    scope: computed(() => state.receiveList.map(item => item.id)),
    options: [{ title: '', img_url: '', img_name: '' }, { title: '', img_url: '', img_name: '' }],
    options_list: [{ name: '', type: '', isRequired: true }]
  },
  voteRules: {
    title: [{ required: true, validator: (rule, value, callback) => checkNull(rule, value, callback, '标题'), trigger: ['blur', 'change'] }],
    deadline_time: [{ required: true, validator: (rule, value, callback) => checkNull(rule, value, callback, '截止时间'), trigger: 'change' }],
    is_option_fixed: [{ required: true }],
    is_anonymous: [{ required: true }],
    max_vote_nums: [{ required: true }],
    scope: [{ type: 'array', required: true, message: '可见范围不能为空', trigger: 'change' }],
    options: [{ required: true, validator: (rule, value, callback) => checkOptionsList(rule, value, callback), trigger: ['blur', 'change'] }],
    options_list: [{ required: true, validator: (rule, value, callback) => checkOptionsList(rule, value, callback), trigger: ['blur', 'change'] }]
  },
  receiveVisible: false,
  receiveUserRef: null,
  receiveList: JSON.parse(JSON.stringify(allUserList.value)), // 接收用户，默认全选
  receiveUserIds: computed(() => state.receiveList.map(item => item.ding_user_id || item.id)),
  receiveUserName: computed(() => state.receiveList.map(item => (item.value || item.name)).join('、')),
  disableUserIds: computed(() => state.receiveList.filter(item => item.disabled).map(item => item.ding_user_id || item.id)),
  currentUser: computed(() => store.state.userInfo.userInfos),
  formatUser: computed(() => {
    return {
      id: state.currentUser.id,
      ding_user_id: state.currentUser.user_id,
      name: state.currentUser.name,
      avatar: state.currentUser.avatar,
      disabled: true
    }
  }),
  limitNum: 2, // 限制输入人数
  maxOptionNum: computed(() => state.voteForm.is_option_fixed ? state.voteForm.options.length : 99999), // 限制输入最大值
  defaultTime: computed(() => {
    const now = new Date()
    const date = reBackTime(new Date(now.getTime() + 25 * 60 * 60 * 1000).getTime())
    return new Date(date.year, date.month, date.day, date.hours, 0, 0)
  }),
  voteRef: null, // 发起投票弹窗
  submitDisabled: false, // 不可提交
  voteOptionSettingRef: null, // 开发式投票-选项设置
  isPrev: false,
  optionSettingVisible: false, // 投票选项设置
  optionSettingRef: null,
  voteOptionFormRef: [], // 封闭式投票-选项表单
  isInit: false // 是否默认填充
})

const {
  voteFormRef, voteForm, voteRules, receiveVisible, receiveUserRef, receiveUserIds, disableUserIds, currentUser,
  receiveUserName, limitNum, maxOptionNum, defaultTime, voteRef, submitDisabled, voteOptionFormRef, voteOptionSettingRef,
  isPrev, optionSettingVisible, optionSettingRef, isInit
} = toRefs(state)
// 切换封闭式票数初始化
watch(() => state.voteForm.is_option_fixed, (newValue) => {
  newValue && (state.limitNum = 2)
})
// 创建投票弹窗初始化
watch(() => store.state.vote.voteVisible, (newValue) => {
  newValue && initData()
})
// 清理投票创建次数
watch(() => store.state.vote.clearNum, (newValue) => {
  resetVoteData()
})
// 初始化回显投票数据
const initData = () => {
  const now = new Date()
  const date = reBackTime(new Date(now.getTime() + 25 * 60 * 60 * 1000).getTime())
  const timeDate = `${date.year}-${date.MM}-${date.DD} ${date.hh}:00`
  // eslint-disable-next-line camelcase
  if (Object.keys(store.state.vote.voteInfos).length > 0) {
    state.voteForm = Object.assign(state.voteForm, store.state.vote.voteInfos)
    state.isInit = true
    const maxVoteNum = state.voteForm.max_vote_nums
    state.limitNum = maxVoteNum
    state.voteForm.max_vote_nums = (maxVoteNum > 1 && maxVoteNum !== 999999) ? 2 : maxVoteNum
    // 投票选项
    state.voteForm.options = state.voteForm.options.length > 0 ? state.voteForm.options : [{ title: '', img_url: '', img_name: '' }, { title: '', img_url: [], img_name: '' }]
    state.voteForm.options_list = state.voteForm.options_list || []
    // 投票配置
    state.voteForm.options_config = state.voteForm.options_config || []
    state.receiveList = allUserList.value.filter(item => store.state.vote.voteInfos.scope.includes(item.id))
    // 自己不可删除
    const selfObj = state.receiveList.filter(item => item.id === state.formatUser.id)
    if (selfObj.length > 0) {
      selfObj[0].disabled = true
    }
  }
  const deadlineTime = state.voteForm.deadline_time || store.state.vote.voteInfos.deadline_time
  state.voteForm.deadline_time = deadlineTime && new Date().getTime() < new Date(deadlineTime).getTime() ? deadlineTime : timeDate
}
initData()

// 时间禁用
const disabledDate = (time) => {
  return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000
}
// 格式化选择时间
const getDateTime = (value) => {
  if (!value) return
  if (new Date(value).getTime() <= new Date().getTime()) {
    state.voteForm.deadline_time = formatAllDate(value, null, 'day') + ' ' + (new Date().getHours() + 1) + ':00'
  } else {
    value.substr(-2) === '00' || (state.voteForm.deadline_time = formatAllDate(value, null, 'hour') + ':00')
  }
}
const getReceiveUser = () => {
  state.receiveVisible = true
}

// 选中用户范围
const addReceiveUser = () => {
  // 先清空，重新渲染选择的用户，避免漏删除
  state.receiveList.length = 0

  state.receiveUserRef.rightData.forEach(item => {
    if (item.dept_id) {
      getTeamUserList({ dept_id: item.dept_id }).then(res => {
        res.data.forEach(user => {
          setReceiveUser(user)
        })
      })
    } else {
      setReceiveUser(item)
    }
  })
  hideReceiveUser()
}

const setReceiveUser = (item) => {
  const user = JSON.parse(JSON.stringify(item))
  const index = state.receiveList.findIndex(user => user.id === item.id)
  index === -1 && state.receiveList.push(user)
}

// 隐藏选择用户范围
const hideReceiveUser = () => {
  state.receiveVisible = false
  // 重置数据
  state.receiveUserRef.resetData()
}

const resetVoteData = () => {
  state.voteFormRef.resetFields()
  state.receiveList = JSON.parse(JSON.stringify(allUserList.value))
  state.voteForm.is_option_fixed = 0
  state.voteForm.is_anonymous = 0
  state.voteForm.title = ''
  state.voteForm.max_vote_nums = 1
  state.voteForm.deadline_time = ''
  state.voteForm.options = [{ title: '', img_url: '', img_name: '' }, { title: '', img_url: '', img_name: '' }]
  state.voteForm.options_list = []
}

// 创建投票
const toCreateVote = () => {
  state.voteFormRef.validate((valid) => {
    if (valid) {
      // eslint-disable-next-line camelcase
      const { is_option_fixed, is_anonymous, title, max_vote_nums, deadline_time, scope, options, id, options_list } = state.voteForm
      const params = { is_option_fixed, is_anonymous, title, max_vote_nums, deadline_time, scope, options, id, options_list }
      params.max_vote_nums = params.max_vote_nums === 2 ? state.limitNum : params.max_vote_nums
      if (params.is_option_fixed === 0) { // 开放式投票
        params.options_config = state.voteOptionSettingRef.optionsList
        delete params.options_list
      } else { // 封闭式投票
        // 自定义投票选项
        if (params.options_list.length > 0) {
          // state.voteOptionFormRef.every(ref => ref.isUploading())
          params.options_list = state.voteOptionFormRef.map(ref => ref.getFormData())
          delete params.options
        }
      }
      store.commit('vote/setVoteVisible', false)
      store.commit('vote/setVoteInfo', params)
    }
  })
}
// 取消创建投票
const close = () => {
  store.commit('vote/setVoteVisible', false)
  !store.state.vote.voteInfos.scope && resetVoteData()
}

// 添加投票选项
const addOption = async () => {
  state.voteForm.options.push({ title: '', img_url: '', img_name: '' })
  await nextTick()
  state.voteRef.wrap.scrollTop = state.voteRef.wrap.scrollHeight
}

// 删除投票选项
const delOption = (index) => {
  state.voteForm.options.splice(index, 1)
  state.limitNum = state.limitNum > state.voteForm.options.length ? state.voteForm.options.length : state.limitNum
}

/**
 * 打开投票设置弹窗
 * @param isPrev 是否预览
 */
const openOptionSettingDialog = (isPrev) => {
  let flag = true
  if (isPrev) { // 预览校验
    // 选项名称或选项类型未正确填写
    flag = checkVoteSetting(state.voteOptionSettingRef.optionsList)
  }
  if (flag) {
    state.isPrev = isPrev
    state.optionSettingVisible = true
    state.voteForm.options_list = isPrev ? state.voteOptionSettingRef?.optionsList : []
  }
}

/**
 *
 * 是否全部非必填
 * @param list 需要校验的数据
 * @param toast 是否需要弹窗, 默认弹窗
 */
const checkVoteSettingRequired = (list, toast = true) => {
  const flag = list.every(item => !item.required)
  if (toast && flag) {
    myToast({ type: 'error', message: '最少设置一个必填项' })
  }
  return flag
}
/**
 *
 * 校验投票选项名称或选项类型是否全部已填写
 * @param list 需要校验的数据
 * @param toast 是否需要弹窗, 默认弹窗
 */
const checkVoteSetting = (list, toast = true) => {
  const flag = list.every(item => item.name && item.type)
  if (toast && !flag) {
    myToast({ type: 'error', message: '选项名称或选项类型未正确填写' })
  }
  return flag
}

// 切换投票类型
const changeRadio = (value, key) => {
  if (key !== 'is_option_fixed') {
    return
  }
  if (value) { // 封闭式投票
    state.voteForm.option = [{ title: '' }, { title: '' }]
    state.voteForm.options_list = []
  } else { // 开放式投票
    state.voteForm.options_list = [{ label: '', type: '', isRequired: true }]
  }
}

// 设置自定义投票选项
const setOptionSetting = () => {
  state.isInit = false
  const list = state.optionSettingRef.optionsList
  // 是否全部非必填
  const requiredLengthCheck = checkVoteSettingRequired(list)
  if (!requiredLengthCheck) {
    // 是否设置选项名称和类型
    const contentCheck = checkVoteSetting(list)
    if (contentCheck) {
      state.voteForm.options_list = list
      state.optionSettingVisible = false
    }
  }
}

</script>
<style lang="less">
.select-time{
  transform: translateY(-10px);
  .el-popper__arrow{
    display: none;
  }
  .el-time-spinner__wrapper{
    width: 100%;
    &:last-child{
      display: none;
    }
  }
  .el-time-panel{
    width: 100px;
  }
  .el-button--text{
    display: none;
  }
}
.vote-select-user{
  width: 651px !important;
  .el-dialog__header, .el-dialog__body{
    padding: 0;
  }
}

.select-user{
  position: absolute;
  background: #F6F9FA;
  padding: 16px 24px 12px;
  z-index: 5;
  border-radius: 6px;
  .user-dialog-title{
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: @default-text-color;
    margin-bottom: 16px;
  }
  .el-dialog__body{
    padding: 6px 24px;
    font-size: 13px;
    color: @default-text-color;
  }
  .el-dialog__title{
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
  }
  .el-button{
    font-size: 14px;
  }
  .el-input__inner{
    padding-right: 45px;
  }
  .el-scrollbar{
    padding: 16px 10px 20px 16px !important;
    .el-input__inner{
      width: 266px;
      height: 33px;
      line-height: 33px;
    }
    .search-box{
      svg{
        top: 7px;
      }
    }
    .el-breadcrumb__inner{
      color: @default-text-color;
    }
    li{
      .el-checkbox{
        margin-right: 6px;
      }
      &:first-child{
        .el-checkbox{
          margin-right: 15px;
        }
      }
      .dep-item,
      .user-info{
        padding: 8px 0 8px 10px;
      }
      .user-info:hover,
      &.rigth-item:hover{
        cursor: pointer;
        background: #F1F4F4;
        border-radius: 6px;
      }
    }
  }
  .button-box{
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    .el-button{
      width: 85px;
      height: 32px;
    }
    .el-button:first-child{
      background: #E4E3FC;
      border-radius: 30px;
      color: @active-text-color;
    }
  }
  input::-webkit-input-placeholder,
  input::-moz-input-placeholder,
  input::-ms-input-placeholder{
    color: #333;
    font-size: 13px;
  }
  .el-input__inner{
    width: 100%;
    height: 30px;
    background: #F6F9FA;
    border: none;
    border-radius: 25px;
    line-height: 30px;
    cursor: pointer;
  }
}

</style>
<style lang="less" scoped>
  .vote-dialog-box{
    width: 586px;
    padding: 24px 0;
    color: @default-text-color;
    cursor: auto;
    position: relative;
    .title{
      color: #000;
      font-weight: 600;
      margin-left: 24px;
      margin-bottom: 20px;
    }
    .vote-close{
      display: block;
      position: absolute;
      top: 20px;
      right: 20px;
      width: 10px;
      height: 10px;
      background: url('../../../../assets/img/vote/close-icon.svg') no-repeat;
      cursor: pointer;
    }
    .el-form{
      margin: 0 24px
    }
    :deep(.el-form-item){
      margin-bottom: 20px;
    }
    .el-form-item-option {
      .el-form-item{
        margin: 30px 0 10px;
        line-height: 20px;
        height: 20px;
        :deep(.el-form-item__label), :deep(.el-form-item__content){
          line-height: 20px;
          height: 20px;
        }
      }
      .el-form-item.is-error{
        margin-bottom: 20px;
      }
    }
    :deep(.el-form-item__label){
      color: #000;
      font-size: 14px;
    }
    :deep(.el-form-item__content){
      width: 100%;
    }
    :deep(.el-radio){
      width: 100px;
    }
    :deep(.el-form-item.is-error .el-input__inner){
      border-color: var(--el-color-danger);
    }
    :deep(.el-input__inner){
      padding: 9px 35px 9px 12px;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      max-width: 420px;
      cursor: pointer;
    }
    :deep(.el-textarea){
      max-width: 420px;
    }
    :deep(.el-radio__label) {
      padding-left: 6px;
      margin-bottom: 5px;
      display: inline-block;
    }
    :deep(.el-radio__inner){
      width: 16px;
      height: 16px;
      border-radius: 2px;
      border-color: var(--el-color-primary);
    }
    :deep(.el-radio__input.is-checked .el-radio__inner::after){
      border-radius: 0px;
      width: 14px;
      height: 14px;
      background: #7972F0 url('../../../../assets/img/vote/selected-white-icon.svg') no-repeat center;
    }
    .limit-num{
      display: flex;
      margin-top: 5px;
      line-height: 1;
      align-items: center;
      &>p{
        font-size: 12px;
        color: #C0C4CC;
        margin-left: 10px;
        margin-top: 10px;
      }
      :deep(.el-input__inner){
        padding-left: 35px;
      }

    }

    :deep(.el-date-editor){
      width: 100%;
      position: relative;
      width: 420px;
      .el-input__inner{
        border-radius: 4px;
        border: 1px solid #DCDFE6;
      }
      .el-input__prefix{
        position: absolute;
        right: 13px;
        left: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-input__icon {
          background: url(../../../../assets/img/datepicter-icon.svg) no-repeat;
          width: 20px;
          height: 20px;
        }
      }
      .el-input__suffix{
        right: 35px;
      }
      .el-icon-time:before{
        content: none;
      }
    }
    .scope-input{
      display: none;
    }
    .scope-desc{
      position: relative;
      padding: 9px 30px 9px 12px;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      height: 40px;
      line-height: 40px;
      color: #C0C4CC;
      font-size: 14px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      max-width: 420px;
      &::before{
        content: '';
        position: absolute;
        top: 16px;
        right: 16px;
        background: url(../../../../assets/img/arrow-down-icon.svg) no-repeat;
        width: 12px;
        height: 8px;
      }
      &.current-text{
        color: var(--el-text-color-regular);
      }
    }
    .option-list-box :deep(.common-icon){
      position: absolute;
      right: 0;
      bottom: 5px;
    }

    .option-list-box :deep(.option-form){
      padding: 10px;
      border-radius: 6px;
      background-color: #FAFAFA;
      max-width: 440px;
    }

    .option-default-name{
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 6px;
    }
    .option-default-list{
      display: flex;
      align-items: center;
    }
    .add-icon{
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 80px;
      width: fit-content;
    }

    .option-draggable-list{
      margin-left: 85px;
    }

    :deep(
    .el-input__inner::-webkit-input-placeholder,
    .el-input__inner::-moz-input-placeholder,
    .el-input__inner::-ms-input-placeholder){
      color: #C0C4CC;
      font-size: 14px;
    }
    .vote-btn, .vote-setting-btn{
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .el-button{
        width: 85px;
        height: 32px;
      }
      .el-button:first-child{
        background: #E4E3FC;
        border-radius: 30px;
        color: @active-text-color;
      }
    }
    &.vote-dialog-setting{
      width: 608px !important;
    }
  }

  .option-list-setting{
    display: flex;
    align-items: center;
    max-width: 400px;
    &.option-list-setting-title{
      justify-content: space-between;
    }
    svg{
      cursor: pointer;
    }
    .first-span{
      margin-right: 90px;
    }
    .primary-text{
      color: @active-text-color;
      margin-right: 4px;
    }
  }

  .view-icon{
    margin-left: 16px;
    margin-right: 4px;
  }

</style>

<style lang="less">
.vote-option-dialog{
  border-radius: 8px;
  .el-dialog__header{
    display: none;
  }
  .el-dialog__body{
    padding: 24px 40px !important;
  }
  .vote-option-dialog-title{
    font-size: 16px;
    font-weight: 600;
    color:#333;
    line-height: 23px;
    text-align: center;
    margin-bottom: 16px;
  }
  .close-icon{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 10px;
    height: 10px;
    background: url('../../../../assets/img/vote/close-icon.svg') no-repeat;
    cursor: pointer;
  }

}

.vote-option-setting-dialog{
  .el-dialog__body{
    padding: 24px 40px !important;
  }
  .vote-option-title-desc{
    display: flex;
    justify-content: space-between;
    margin: 25px 20px 12px;
    width: 410px;
    .first-span{
      margin-right: 50px;
    }
  }
  .option-draggable-list{
    margin-left: 15px !important;
  }
}

</style>
